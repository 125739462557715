import classes from './About.module.scss';
import CoFounderImg from '../../assets/images/co-founder.svg';
import GirlHi from '../../assets/images/girl-hi.svg';
import GirlSpeaker from '../../assets/images/girl-speaker.svg';
import SpeakerLeft from '../../assets/images/speaker-left.svg';
import BoyRightHalf from '../../assets/images/boy-right-half.svg';

export function About() {
  return (
    <div className={classes.wrapper}>
      <section className={`${classes.about}`} id='about-us'>
        <div className={`${classes.about__header} aboutUs-heading`}>
          <h3 className={classes['about__header__sub-heading']}>
            We are a Digital agency with a human touch because that’s always
            important
          </h3>
          <h3 className={classes['about__header__heading']}>
            MACH1 dIGITAL CO-FOUNDER’S
          </h3>
        </div>
        <div className={classes['about__co-founder']}>
          <div className={`${classes['about__co-founder__content']} co-founder-content`}>
            <img src={CoFounderImg} alt="Co-founder" />
            <p
              className={`${classes['about__co-founder__content__sub-title']} co-founder-p`}
            >{`(co-founder)`}</p>
            <div className={classes['about__card']}>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Repellendus qui illum, quidem nesciunt fugit aperiam nam quia
                sunt obcaecati necessitatibus aut sapiente similique sequi
                voluptatum reprehenderit libero! Assumenda, eum cumque!
              </p>
            </div>
          </div>
          <div className={`${classes['about__co-founder__img']} co-founder-image`}>
            <img src={GirlHi} alt="Girl" />
          </div>
        </div>
        <div className={classes['about__hey-founder']}>
          <div className={classes['about__hey-founder--left']}>
            <img src={GirlSpeaker} alt="Girl Speaker" />
            <div className={classes['about__hey-founder__speaker-content']}>
              <img src={SpeakerLeft} alt="Speaker" />
            </div>
          </div>
          <div className={classes['about__hey-founder--right']}>
            <img src={BoyRightHalf} alt="Boy" />
          </div>
        </div>
      </section>
    </div>
  );
}
