import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimateText } from '../../components';
import { NAVBAR_OPTIONS } from './Navbar.util';
import PlayIcon from '../../assets/icons/play.svg';
import PauseIcon from '../../assets/icons/pause.svg';
import classes from './Navbar.module.scss';

export function Navbar(props) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const navigate = useNavigate();

  function onLinkClickHandler(link) {
    navigate(link);
    props.navbarOptionVisible(false);
    setMenuOpen(false);
  }

  function onBannerClickHandler(e) {
    props.onShowBannerClick(e);
  }

  return (
    <>
      <nav
        className={`${classes.navbar} ${
          isMenuOpen ? classes['navbar__menu'] : ''
        }`}
      >
        <div className={classes['navbar__content']}>
          {/* Main Website Logo */}
          <svg
            onClick={() => {
              onLinkClickHandler('/');
            }}
            className={classes['navbar__logo']}
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 105 46"
            fill="white"
          >
            <path d="M0 0L43.4192 45.8958L0 46.0001V0Z" fill="inherit" />
            <path
              d="M86.9902 0L43.4192 45.8958L86.9902 46.0001V0Z"
              fill="inherit"
            />
            <rect x="92.3042" width="12.4489" height="46.0001" fill="inherit" />
          </svg>

          <div className={classes['navbar__option-container']}>
            {/* Option Icon */}
            <svg
              onClick={() => {
                if(!isMenuOpen) {
                  document.body.style.overflow = 'hidden';
                } else {
                  document.body.style.overflow = '';
                }
                props.navbarOptionVisible(!isMenuOpen);
                setMenuOpen((prev) => !prev);
              }}
              className={classes['navbar__option']}
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
            >
              <rect
                width="48"
                height="48"
                transform="translate(0.75293)"
                fill="white"
              />
              <path
                d="M12.7529 25H15.7529L19.7529 14L22.7529 36L27.7529 23L31.7529 29L34.7529 25H37.7529"
                stroke="black"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            {props.audioPlay && (
              <img
                onClick={() => {
                  props.audioToggle();
                }}
                className={`${classes['navbar__option']} ${classes['navbar__option--audio']}`}
                src={PauseIcon}
                alt="Pause audio"
              />
            )}
            {!props.audioPlay && (
              <img
                onClick={() => {
                  props.audioToggle();
                }}
                className={`${classes['navbar__option']} ${classes['navbar__option--audio']}`}
                src={PlayIcon}
                alt="Play audio"
              />
            )}
          </div>
        </div>

        {/* Navbar Options */}
        {isMenuOpen && (
          <div className={classes['navbar__menu__option']}>
            {NAVBAR_OPTIONS.map((option) => (
              <AnimateText
                onClick={onLinkClickHandler}
                link={option.to}
                key={option.id}
                keyValue={option.id}
                text={option.name}
              />
            ))}
          </div>
        )}
      </nav>

      {props.showBanner && (
        <div className={classes['banner--overlay']}>
          <div className={classes['banner']}>
            <h3 className={classes['banner__heading']}>Audio Permission</h3>
            <p className={classes['banner__content']}>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Commodi
              dolore et, laboriosam modi sapiente repellendus accusamus quo
              cumque assumenda, id debitis sit maxime expedita culpa, minus
              consequatur animi! Quibusdam, maiores.
            </p>
            <div className={classes['banner__actions']}>
              <button
                name="accept"
                onClick={onBannerClickHandler}
                className={`${classes['banner__btn']} ${classes['banner__btn--primary']}`}
              >
                Accept
              </button>
              <button
                name="decline"
                onClick={onBannerClickHandler}
                className={classes['banner__btn']}
              >
                Decline
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
