import { useEffect, useRef, useState } from 'react';
import MachLogo from '../../assets/logo/logo.svg';
import MenuLeftImage from '../../assets/images/menu-left.svg';
import MenuRightImage from '../../assets/images/menu-right.svg';
import classes from './Editor.module.scss';
import { Celebration } from '../Celebration';

export function Editor() {
  const companyRef = useRef();
  const targetRef = useRef();
  const tooltipRef = useRef();
  const editorContentRef = useRef();
  const [celebrationState, setCelebrationState] = useState(false);

  useEffect(() => {
    function animation(companyDimension) {
      return new Promise((response, reject) => {
        targetEle.style.setProperty(
          '--left-value',
          companyDimension.left -
            targetEle.getBoundingClientRect().left +
            companyDimension.width / 2 -
            targetEle.getBoundingClientRect().width / 2 +
            'px',
        );
        targetEle.classList.add(classes['editor__action__target--animate']);
        setTimeout(() => {
          targetEle.classList.remove(
            classes['editor__action__target--animate'],
          );
          response();
        }, 1500);
      });
    }
    async function onDragHandler(e) {
      const { x, y } = e;
      const companyDimension = companyRef.current.getBoundingClientRect();
      console.log(companyDimension, x, y);
      if (
        x >= companyDimension.left &&
        x <= companyDimension.right &&
        y >= companyDimension.top &&
        y <= companyDimension.bottom
      ) {
        dragCount = dragCount + 1;
        console.log(dragCount);
        if (dragCount === 1) {
          companyRef.current.classList.add(
            classes['editor__action__target--glow'],
          );
          await animation(companyDimension);
          tooltipRef.current.innerText = 'drag Mach1 again';
        } else if (dragCount === 2) {
          companyRef.current.children[0].classList.remove('display-none');
          companyRef.current.style.scale = 1.25;
          tooltipRef.current.innerText = 'drag Mach1 once more';
          await animation(companyDimension);
        } else if (dragCount === 3) {
          companyRef.current.style.scale = 1.5;
          targetEle.classList.add('display-none');
          await animation(companyDimension);
          setCelebrationState(true);
          setTimeout(() => {
            setCelebrationState(false);
          }, 4000);
          tooltipRef.current.classList.add('display-none');
        }
      }
    }
  
    let dragCount = 0;
    const targetEle = targetRef.current;
    targetEle.addEventListener('dragend', onDragHandler);

    function onMouseEnterEditorContent(e) {
      if (!targetRef.current.classList.contains('display-none')) {
        tooltipRef.current.classList.remove('display-none');
      }
    }
    function onMouseMoveInEditorContent(e) {
      const tooltipEle = tooltipRef.current;
      tooltipEle.style.top = e.clientY + 10 + 'px';
      tooltipEle.style.left = e.clientX + 10 + 'px';
    }
    function onMouseLeaveEditorContent(e) {
      tooltipRef.current.classList.add('display-none');
    }

    const editorContentEle = editorContentRef.current;
    editorContentEle.addEventListener('mouseenter', onMouseEnterEditorContent);
    editorContentEle.addEventListener('mousemove', onMouseMoveInEditorContent);
    editorContentEle.addEventListener('mouseleave', onMouseLeaveEditorContent);

    return () => {
      editorContentEle.removeEventListener(
        'mouseenter',
        onMouseEnterEditorContent,
      );
      editorContentEle.removeEventListener(
        'mousemove',
        onMouseMoveInEditorContent,
      );
      editorContentEle.removeEventListener(
        'mouseleave',
        onMouseLeaveEditorContent,
      );
      targetEle.removeEventListener('dragend', onDragHandler);
    };
  }, []);

  return (
    <section className={classes.container}>
      <div className={classes.editor}>
        <div className={classes.editor__header}>
          <img src={MachLogo} alt="Mach 1" />
          <h3>drag Mach1 To your brand</h3>
        </div>
        <div ref={editorContentRef} className={classes.editor__content}>
          <p
            ref={tooltipRef}
            className={`${classes['editor__content__tooltip']} display-none`}
          >
            drag Mach1
          </p>
          <div className={classes.editor__bar}>
            <img src={MenuLeftImage} alt="Menu Left" />
          </div>
          <div className={classes.editor__action}>
            {celebrationState && <Celebration />}
            <div
              draggable
              ref={targetRef}
              className={`${classes['editor__action__target']}`}
            >
              <img
                className={`${classes['editor__action__target__logo']}`}
                src={MachLogo}
                alt="Mach 1"
                draggable={false}
              />
            </div>
            <div
              ref={companyRef}
              className={classes['editor__action__company']}
            >
              <img className={`display-none`} src={MachLogo} alt="Mach 1" />
              your brand
            </div>
          </div>
          <div className={classes.editor__bar}>
            <img src={MenuRightImage} alt="Menu Right" />
          </div>
        </div>
      </div>
    </section>
  );
}
