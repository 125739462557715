import classes from './Hero.module.scss';
import { useEffect } from 'react';
import { customGalaxy } from './galaxy';

export function Hero(props) {
  useEffect(() => {
    customGalaxy();
  }, []);

  return (
    <>
      <section className={classes.hero}>
        <div className={classes['hero__canvas-container']} id="container"></div>
        <div id="veil"></div>
        {/* <canvas ref={canvasRef} id='mountain-canvas' className={classes['hero__canvas']} /> */}
        {props.showContent && (
          <>
            <div className={classes['hero__container']}>
              <div className={classes['hero__name']}>
                <span data="M" className={classes['hero__name__letter']}>
                  M
                </span>
                <span data="A" className={classes['hero__name__letter']}>
                  A
                </span>
                <span data="C" className={classes['hero__name__letter']}>
                  C
                </span>
                <span data="H" className={classes['hero__name__letter']}>
                  H
                </span>
                <span data=" " className={classes['hero__name__letter']}>
                  &nbsp;
                </span>
                <span data="1" className={classes['hero__name__letter']}>
                  1
                </span>
              </div>
              {props.content && (
                <>
                  <p className={classes['hero__content']}>
                    {props.content.heading}
                  </p>
                  <p className={classes['hero__sub-content']}>
                    {props.content.description}
                  </p>
                </>
              )}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={classes['hero__button']}
              >
                hire us
              </button>
            </div>
            <div className={classes['hero__footer']}>
              <p
                className={classes['hero__footer__marquee']}
                data="10X YOUR PRESENCE, 10X YOUR SALES"
              >
                &nbsp;&nbsp;&nbsp;•
              </p>
            </div>
          </>
        )}
      </section>
    </>
  );
}
