import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home, AboutUs, WhatWeDo, CallMe } from './pages';
import { Layout } from './components';
import './App.scss';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    localStorage.setItem('face-animation', true);
  }, []);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index exact element={<Home />} />
          <Route path="whatWeDo" exact element={<WhatWeDo />} />
          <Route path="aboutUs" exact element={<AboutUs />} />
          <Route path="callMe" exact element={<CallMe />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
