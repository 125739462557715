import classes from './Celebration.module.scss';

export function Celebration() {
  return (
    <div className={classes["confetti"]}>
      <div className={classes["confetti__piece"]}></div>
      <div className={classes["confetti__piece"]}></div>
      <div className={classes["confetti__piece"]}></div>
      <div className={classes["confetti__piece"]}></div>
      <div className={classes["confetti__piece"]}></div>
      <div className={classes["confetti__piece"]}></div>
      <div className={classes["confetti__piece"]}></div>
      <div className={classes["confetti__piece"]}></div>
      <div className={classes["confetti__piece"]}></div>
      <div className={classes["confetti__piece"]}></div>
      <div className={classes["confetti__piece"]}></div>
      <div className={classes["confetti__piece"]}></div>
      <div className={classes["confetti__piece"]}></div>
    </div>
  );
}
