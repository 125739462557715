import { useOutletContext } from 'react-router-dom';
import { Footer, Hero, ImageMarquee, WhatWeDoComp } from '../../components';
import { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';

export function WhatWeDo() {

  const contextFunctions = useOutletContext();
  const pageRef = useRef();

  useLayoutEffect(()=>{
    const ctx = gsap.context(()=>{
      const whatWeDoSectionTL = gsap.timeline({
        scrollTrigger: {
          trigger: '#whatWeDo-section',
          start: '0% top',
          end: '80%',
          scrub: 2,
        }
      });

      whatWeDoSectionTL.from('.card-0', {
        x: -800,
        opacity: 0,
        duration: 1
      })
      whatWeDoSectionTL.from('.card-1', {
        x: 800,
        opacity: 0,
        duration: 2
      })
      whatWeDoSectionTL.from('.card-2', {
        x: -800,
        opacity: 0,
        duration: 3
      })
      whatWeDoSectionTL.from('.card-3', {
        x: 800,
        opacity: 0,
        duration: 4
      })
    }, pageRef);

    return () => {
      ctx.revert();
    }
  },[])

  return (
    <div ref={pageRef}>
      <Hero showContent={!contextFunctions[1]} />
      <WhatWeDoComp />
      <ImageMarquee />
      <Footer />
    </div>
  );
}
