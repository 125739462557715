import { useOutletContext } from 'react-router';
import { About, Footer, Hero, ImageMarquee } from '../../components';
import { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';

export function AboutUs() {
  const pageRef = useRef();
  const contextFunctions = useOutletContext();

  useLayoutEffect(()=>{
    const ctx = gsap.context(()=>{
      const aboutUsContentTL = gsap.timeline({
        scrollTrigger: {
          trigger: '#about-us',
          start: '0% top',
        }
      });
      aboutUsContentTL.from('.aboutUs-heading', {
        scale: 0.2,
        duration: 1,
        opacity: 0.2 
      })

      aboutUsContentTL.from('.co-founder-content img', {
        x: "-100%",
        duration: 1,
        opacity: 0
      })
      aboutUsContentTL.from('.co-founder-p', {
        x: "-100%",
        duration: 1,
        opacity: 0
      })

      aboutUsContentTL.from('.co-founder-image', {
        x: '100%',
        duration: 1,
        opacity: 0
      })

      aboutUsContentTL.from('.co-founder-content div', {
        x: "-100%",
        duration: 1,
        opacity: 0
      })

    }, pageRef);

    return () => {
      ctx.revert();
    };
  },[]);

  return (
    <div ref={pageRef}>
      <Hero showContent={!contextFunctions[1]} />
      <About />
      <ImageMarquee />
      <Footer />
    </div>
  );
}
