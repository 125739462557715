export const MARKETING_DATA = [
    {
        id: 1,
        heading: 'social media marketing',
        subHeading: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, incidunt, perferendis dolore laboriosam maiores harum adipisci qui error eius aut officiis architecto alias, amet similique provident suscipit aliquid tempore? Sed',
        list: {
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            items: ['lorem ipsum', 'lorem ipsum', 'lorem ipsum']
        }

    },
    {
        id: 2,
        heading: 'e-commerce management',
        subHeading: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, incidunt, perferendis dolore laboriosam maiores harum adipisci qui error eius aut officiis architecto alias, amet similique provident suscipit aliquid tempore? Sed',
        list: {
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            items: ['lorem ipsum', 'lorem ipsum', 'lorem ipsum']
        }

    },
    {
        id: 3,
        heading: 'paid ads management',
        subHeading: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, incidunt, perferendis dolore laboriosam maiores harum adipisci qui error eius aut officiis architecto alias, amet similique provident suscipit aliquid tempore? Sed',
        list: {
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            items: ['lorem ipsum', 'lorem ipsum', 'lorem ipsum']
        }

    },
    {
        id: 4,
        heading: 'ui/ux website & app design',
        subHeading: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, incidunt, perferendis dolore laboriosam maiores harum adipisci qui error eius aut officiis architecto alias, amet similique provident suscipit aliquid tempore? Sed',
        list: {
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            items: ['lorem ipsum', 'lorem ipsum', 'lorem ipsum']
        }

    },
]