import classes from './AITool.module.scss';

export function AITool() {
  return (
    <section className={classes['ai-tool']}>
      <div className={classes['ai-tool__header']}>
        <span className={classes['ai-tool__header__text']}>DAll•E</span>
        <span className={classes['ai-tool__header__text']}>***</span>
        <span className={classes['ai-tool__header__text']}>
          Ai system genreated designs
        </span>
        <span className={classes['ai-tool__header__text']}>***</span>
        <span className={classes['ai-tool__header__text']}>original</span>
        <span className={classes['ai-tool__header__text']}>***</span>
        <span className={classes['ai-tool__header__text']}>
          realistic images
        </span>
      </div>
      <div className={classes['ai-tool__container']}>
        <div className={classes['ai-tool__container__header']}>
          <div className={classes['ai-tool__option']}>
            <span className={classes['ai-tool__dot']}></span>
            <span className={classes['ai-tool__dot']}></span>
            <span className={classes['ai-tool__dot']}></span>
          </div>
          <span className={classes['ai-tool__container__heading']}>
            Play Ground for Your imagination
          </span>
          <button
            className={`${classes['ai-tool__btn']} ${classes['ai-tool__btn--dark']}`}
          >
            reset
          </button>
        </div>
        <div className={classes['ai-tool__container__head']}>
          <span>Start with a detailed description</span>
          <button
            className={`${classes['ai-tool__btn']} ${classes['ai-tool__btn--light']}`}
          >
            Surprise me
          </button>
        </div>
        <div className={classes['ai-tool__container__main']}>
          <textarea name="description" id="description" defaultValue='a cat submarine chimera, digital art'></textarea>
          <button
            className={`${classes['ai-tool__btn']} ${classes['ai-tool__btn--dark']}`}
          >
            generate
          </button>
        </div>
        <div className={classes['ai-tool__container__footer']}></div>
      </div>
    </section>
  );
}
