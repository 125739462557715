export const NAVBAR_OPTIONS = [
    {
        id: 'home',
        name: 'home',
        to: '/'
    },
    {
        id: 'aboutus',
        name: 'about us',
        to: '/aboutUs'
    },
    {
        id: 'whatwedo',
        name: 'what we do',
        to: '/whatWeDo'
    },
    {
        id: 'contactus',
        name: 'contact us',
        to: '/callMe'
    },
]