import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import stars from '../../assets/images/circle.png';

const loader = new THREE.TextureLoader();
const cross = loader.load(stars);

// Scene
const scene = new THREE.Scene();

const openDialogButton = document.getElementById('openDialogButton');
const dialogs = document.getElementsByClassName('dialog');
const closeButtons = document.getElementsByClassName('closeButton');
// let currentDialogIndex = 0;

export const handleUI = (clickCounter) => {
  if (clickCounter === 0) {
    // var ele = document.getElementById(data);
    var objLoader = new OBJLoader();

    objLoader.load(
      'https://s3-us-west-2.amazonaws.com/s.cdpn.io/40480/head.obj',
      function (object) {
        // Create wireframe
        var geometry = new THREE.BufferGeometry();

        // Access vertices of the loaded model and add them to the geometry
        object.traverse(function (child) {
          if (child instanceof THREE.Mesh) {
            var vertices = child.geometry.attributes.position.array;
            geometry.setAttribute(
              'position',
              new THREE.BufferAttribute(vertices, 3),
            );
          }
        });
        var material = new THREE.PointsMaterial({
          map: cross,
          size: 0.09,
        });

        var wireframe = new THREE.Points(geometry, material);
        wireframe.position.z = -27;
        scene.add(wireframe);
      },
    );
  } else if (clickCounter === 1) {
    var objLoader = new OBJLoader();

    objLoader.load(
      'https://s3-us-west-2.amazonaws.com/s.cdpn.io/40480/head.obj',
      function (object) {
        // Create wireframe
        var geometry = new THREE.BufferGeometry();

        // Access vertices of the loaded model and add them to the geometry
        object.traverse(function (child) {
          if (child instanceof THREE.Mesh) {
            var vertices = child.geometry.attributes.position.array;
            geometry.setAttribute(
              'position',
              new THREE.BufferAttribute(vertices, 3),
            );
          }
        });
        var material = new THREE.MeshBasicMaterial({
          wireframe: true,
          color: 0xff0000,
        });

        var wireframe = new THREE.Mesh(geometry, material);
        wireframe.position.z = -27;
        scene.add(wireframe);
      },
    );
  }
};

export function faceCanvasUtil(canvasRef) {
  /**
   * Sizes
   */

  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  // Canvas
  const canvas = canvasRef;

  window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  });

  handleUI(0);

  /**
   * Camera
   */
  // Base camera
  const camera = new THREE.PerspectiveCamera(
    75,
    sizes.width / sizes.height,
    0.1,
    100,
  );
  camera.position.set(0, 0, 2);
  scene.add(camera);

  /**
   * Renderer
   */
  const renderer = new THREE.WebGLRenderer({
    antialias: false,
    canvas: canvas,
  });
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(window.devicePixelRatio * 0.8);
  renderer.setClearColor(new THREE.Color('#000'), 0.1);

  const targetCameraPosition = { x: 0, y: 0 };
  const lerpFactor = 0.1; // Adjust this value for the smoothness (lower value for smoother movement)

  canvas.addEventListener('mousemove', (event) => {
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    // Convert mouse coordinates to the range [-1, 1]
    const normalizedMouseX = (mouseX / window.innerWidth) * 2 - 1;
    const normalizedMouseY = -(mouseY / window.innerHeight) * 2 + 1;

    // Calculate the target camera position based on mouse movement
    targetCameraPosition.x = normalizedMouseX * 1.0;
    targetCameraPosition.y = normalizedMouseY * 1.0;
  });

  const updateCameraPosition = () => {
    // Smoothly interpolate camera position towards the target position
    camera.position.x = THREE.MathUtils.lerp(
      camera.position.x,
      targetCameraPosition.x,
      lerpFactor,
    );
    camera.position.y = THREE.MathUtils.lerp(
      camera.position.y,
      targetCameraPosition.y,
      lerpFactor,
    );
  };

  /**
   * Animation loop
   */
  const animate = () => {
    updateCameraPosition();
    renderer.render(scene, camera);
    requestAnimationFrame(animate);
  };

  // Start the animation loop
  animate();

  const clock = new THREE.Clock();

  const tick = () => {
    const elapsedTime = clock.getElapsedTime();
    renderer.render(scene, camera);

    window.requestAnimationFrame(tick);
  };
  tick();
}
