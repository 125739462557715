import { Canvas, useFrame } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import Mach1Object from '../../assets/models/MACH11.obj';
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { Suspense, useRef, useState } from "react";

const Scene = () => {
  const obj = useLoader(OBJLoader, Mach1Object);
  const objRef = useRef();
  const time = useRef(0);
  const [clonedObjRef] = useState(() => obj.clone()); //clone the object

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    time.current = elapsedTime * 0.5;

    // Calculate the movement range and speed
    const movementRange = 2; // Adjust the range of movement
    const movementSpeed = 2.0; // Adjust the speed of movement

    // Calculate the current movement position
    const movement = Math.sin(time.current * movementSpeed) * (movementRange / 3);

    // Apply smooth movement along different axes
    objRef.current.position.x = movement;
    // objRef.current.position.y = bounce;
    objRef.current.position.z = 0; 

    const movement2 = -Math.sin(time.current * movementSpeed) * (movementRange / 3);

    clonedObjRef.position.x = movement2;
    
    clonedObjRef.position.z = 2.2;
  });

  return (
    <>
      <group ref={objRef}>
        <primitive object={obj} scale={0.4} />
      </group>
      <group ref={clonedObjRef}>
        <primitive object={clonedObjRef} scale={0.4} />
      </group>
    </>
  );
};

export function Model() {
  return (
    <Canvas style={{ maxWidth: '45vw' }} camera={{ position: [0, 0, 30] }}>
      <ambientLight intensity={0.2} color="#ff0000" />
      <directionalLight intensity={1} position={[1.87, 0, 0]} color="#ffffff" />
      <Suspense fallback={null}>
        <Scene />
      </Suspense>
    </Canvas>
  );
}
