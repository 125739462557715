import { useEffect, useRef } from 'react';
import classes from './CanvasBackground.module.scss';
import { galaxyAnimation } from './galaxy.util';
import { spiralAnimation } from './spiral.util';

/**
 *
 * @param { type } props
 * type: 'galaxy' | 'spiral'
 */

export function CanvasBackground({ type }) {
  const galaxycanvasRef = useRef(null);
  const spiralCanvasRef = useRef(null);

  useEffect(() => {
    if (type === 'galaxy') {
      galaxyAnimation(galaxycanvasRef.current);
    } else if (type === 'spiral') {
      spiralAnimation(spiralCanvasRef.current);
    }
  }, [type]);

  return (
    <>
      {type === 'galaxy' && (
        <canvas ref={galaxycanvasRef} className={classes.canvas} />
      )}
      {type === 'spiral' && (
        <canvas ref={spiralCanvasRef} className={classes.canvas} />
      )}
    </>
  );
}
