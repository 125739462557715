// ThreeJS and Third-party deps
import * as THREE from 'three';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import stars from '../../assets/images/circle.png';
// import * as Stats from 'stats.js'
// Core boilerplate code deps
import {
  createCamera,
  createComposer,
  createRenderer,
  runApp,
} from './core-utils';

import { loadImage } from './common-utils';
import {
  getZFromImageDataPoint,
} from './functions';
// import Background from '../../assets/images/starsgalaxy.jpg';
import HeightMap from '../../assets/images/heightmap.png';

const loader = new THREE.TextureLoader();
const cross = loader.load(stars);

// const stats = new Stats()
// stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
// document.body.appendChild(stats.dom)



export function customGalaxy() {
  global.THREE = THREE;

  /**************************************************
   * 0. Tweakable parameters for the scene
   *************************************************/
  const params = {
    // general scene params
    speed: 1,
    dirLightColor1: 0xffffff,
    dirLightColor2: 0xff0000,
    // bloom params
    bloomStrength: 0.5,
    bloomRadius: 0.2,
    bloomThreshold: 0.5,
    // plane params
    metalness: 0.2,
    roughness: 0.7,
    meshColor: 0x000,
    meshEmissive: 0x000,
    lineWidth: 0.02,
    lineColor: 0xff0000,
  };
  const terrainWidth = 50;
  const terrainHeight = 30;
  const lightPos1 = {
    x: 15,
    y: 1,
    z: 5,
  };
  const lightIntensity1 = 0.85;
  const lightPos2 = {
    x: -15,
    y: 1,
    z: 5,
  };
  const lightIntensity2 = 0.85;
  // need to be even number since the consecutive terrains are stitched in pairs
  const numOfMeshSets = 6;
  // const sunPos = {
  //   x: 0,
  //   y: 16,
  //   z: -100,
  // };
  // const uniforms = {
  //   ...getDefaultUniforms(),
  //   color_main: {
  //     // sun's top color
  //   },
  //   color_accent: {
  //     // sun's bottom color
  //   },
  // };

  /**************************************************
   * 1. Initialize core threejs components
   *************************************************/
  // Create the scene
  let scene = new THREE.Scene();

  // Create the renderer via 'createRenderer',
  // 1st param receives additional WebGLRenderer properties
  // 2nd param receives a custom callback to further configure the renderer
  let renderer = createRenderer({
    antialias : false,
  });
  renderer.setClearColor(0xffffff, 0);
  renderer.setPixelRatio(window.devicePixelRatio);


  // Create the camera
  // Pass in fov, near, far and camera position respectively
  let camera = createCamera(70, 1, 120, { x: 0, y: 0, z: 2.4 });

  // The RenderPass is already created in 'createComposer'
  // Post-processing with Bloom effect
  let bloomPass = new UnrealBloomPass(
    new THREE.Vector2(window.innerWidth, window.innerHeight),
    params.bloomStrength,
    params.bloomRadius,
    params.bloomThreshold,
  );
  let composer = createComposer(renderer, scene, camera, (comp) => {
    comp.addPass(bloomPass);
  });

  /**************************************************
   * 2. Build your scene in this threejs app
   * This app object needs to consist of at least the async initScene() function (it is async so the animate function can wait for initScene() to finish before being called)
   * initScene() is called after a basic threejs environment has been set up, you can add objects/lighting to you scene in initScene()
   * if your app needs to animate things(i.e. not static), include a updateScene(interval, elapsed) function in the app as well
   *************************************************/
  let app = {
    async initScene() {
      // OrbitControls
      // this.controls = new OrbitControls(camera, renderer.domElement)
      // this.controls.enableDamping = true

      // Environment
      // await loadSceneBackground(scene, Background)

      // Lighting
      this.dirLight1 = new THREE.DirectionalLight(
        params.dirLightColor1,
        lightIntensity1,
      );
      this.dirLight1.position.set(lightPos1.x, lightPos1.y, lightPos1.z);
      scene.add(this.dirLight1);
      this.dirLight2 = new THREE.DirectionalLight(
        params.dirLightColor2,
        lightIntensity2,
      );
      this.dirLight2.position.set(lightPos2.x, lightPos2.y, lightPos2.z);
      scene.add(this.dirLight2);

      // create sets of objects, for the capability to use different heightmaps for each set of plane and lines
      let planeGeometries = [];
      let lineGeometries = [];
      let geometryPositionsArray = [];

      // we only loop twice here, although we load a single HeightMap, the trick is:
      // first loop we load the HeightMap the normal way
      // second loop we load the HeightMap data horizontally inversed
      for (let i = 0; i < 2; i++) {
        // load heightmap to a new image first, then read its color data to set the heights of our plane vertices
        // see: https://gist.github.com/jawdatls/465d82f2158e1c4ce161
        let hm_image = await loadImage(HeightMap);
        var canvas = document.createElement('canvas');
        canvas.width = hm_image.width;
        canvas.height = hm_image.height;
        var context = canvas.getContext('2d');
        context.drawImage(hm_image, 0, 0);
        var hm_imageData = context.getImageData(
          0,
          0,
          canvas.width,
          canvas.height,
        );

        // Create a PlaneGeom
        let planeGeometry = new THREE.PlaneGeometry(
          terrainWidth,
          terrainHeight,
          terrainWidth,
          terrainHeight,
        );
        let geometryPositions = planeGeometry.getAttribute('position').array;
        let geometryUVs = planeGeometry.getAttribute('uv').array;

        // update each vertex position's z value according to the value we extracted from the heightmap image
        for (let index = 0; index < geometryUVs.length / 2; index++) {
          let vertexU = geometryUVs[index * 2];
          let vertexV = geometryUVs[index * 2 + 1];
          // Update the z positions according to height map, inverse heightmap horizontally for the second loop
          let terrainHeight = getZFromImageDataPoint(
            hm_imageData,
            i === 0 ? vertexU : 1 - vertexU,
            vertexV,
            canvas.width,
            canvas.height,
          );
          geometryPositions[index * 3 + 2] = terrainHeight;
        }
        // skew the plane geometry
        const shearMtx = new THREE.Matrix4();
        shearMtx.makeShear(-0.5, 0, 0, 0, 0, 0);
        planeGeometry.applyMatrix4(shearMtx);

        planeGeometries.push(planeGeometry);
        geometryPositionsArray.push(geometryPositions);
      }

      // zip up the gaps between the 1st and 2nd plane geometries
      for (let index = 0; index <= terrainWidth; index++) {
        let bottomOffset = (terrainWidth + 1) * terrainHeight;
        // 2nd geom's bottom row height should be synced with 1st geom's top
        geometryPositionsArray[1][(bottomOffset + index) * 3 + 2] =
          geometryPositionsArray[0][index * 3 + 2];
        // 1st geom's bottom row height should be synced with 2nd geom's top
        geometryPositionsArray[0][(bottomOffset + index) * 3 + 2] =
          geometryPositionsArray[1][index * 3 + 2];
      }

      // material for the plane geometry
      let meshMaterial = new THREE.MeshStandardMaterial({
        color: new THREE.Color(params.meshColor),
        emissive: new THREE.Color(params.meshEmissive),
        metalness: params.metalness,
        roughness: params.roughness,
        flatShading: true,
      });

      // the grid lines, reference: https://threejs.org/examples/?q=line#webgl_lines_fat
      for (let i = 0; i < 2; i++) {
        let lineGeometry = new LineGeometry();
        let linePositions = [];
        // This is a specific way to map line points to corresponding vertices of the planeGeometry
        for (let row = 0; row < terrainHeight; row++) {
          let isEvenRow = row % 2 === 0;
          for (
            let col = isEvenRow ? 0 : terrainWidth - 1;
            isEvenRow ? col < terrainWidth : col >= 0;
            isEvenRow ? col++ : col--
          ) {
            for (
              let point = isEvenRow ? 0 : 3;
              isEvenRow ? point < 4 : point >= 0;
              isEvenRow ? point++ : point--
            ) {
              let mappedIndex;
              let rowOffset = row * (terrainWidth + 1);
              if (point < 2) {
                mappedIndex = rowOffset + col + point;
              } else {
                mappedIndex = rowOffset + col + point + terrainWidth - 1;
              }

              linePositions.push(geometryPositionsArray[i][mappedIndex * 3]);
              linePositions.push(
                geometryPositionsArray[i][mappedIndex * 3 + 1],
              );
              linePositions.push(
                geometryPositionsArray[i][mappedIndex * 3 + 2],
              );
            }
          }
        }
        lineGeometry.setPositions(linePositions);

        lineGeometries.push(lineGeometry);
      }

      // the material for the grid lines
      let lineMaterial = new LineMaterial({
        color: params.lineColor,
        linewidth: params.lineWidth, // in world units with size attenuation, pixels otherwise
        alphaToCoverage: false,
        worldUnits: true, // such that line width depends on world distance
      });

      this.meshGroup = [];
      this.lineGroup = [];
      // create multiple sets of plane and line meshes determined by numOfMeshSets
      for (let i = 0; i < numOfMeshSets; i++) {
        // create the meshes
        let mesh = new THREE.Mesh(planeGeometries[i % 2], meshMaterial);
        let line = new Line2(lineGeometries[i % 2], lineMaterial);
        line.computeLineDistances();
        // set the correct pos and rot for both the terrain and its wireframe
        mesh.position.set(0, -1.5, -terrainHeight * i);
        mesh.rotation.x -= Math.PI / 2;
        line.position.set(0, -1.5, -terrainHeight * i);
        line.rotation.x -= Math.PI / 2;
        // add the meshes to the scene
        scene.add(mesh);
        scene.add(line);
        this.meshGroup.push(mesh);
        this.lineGroup.push(line);
      }

      // scene.add(sun)

     
    },
    // @param {number} interval - time elapsed between 2 frames
    // @param {number} elapsed - total time elapsed since app start
    updateScene(interval, elapsed) {
      // this.controls.update()

      for (let i = 0; i < numOfMeshSets; i++) {
        this.meshGroup[i].position.z += interval * params.speed;
        this.lineGroup[i].position.z += interval * params.speed;
        if (this.meshGroup[i].position.z >= terrainHeight) {
          this.meshGroup[i].position.z -= numOfMeshSets * terrainHeight;
          this.lineGroup[i].position.z -= numOfMeshSets * terrainHeight;
        }
      }
    },
  };



   // Buffer Geometry
   const particlesGeometry = new THREE.BufferGeometry();
   const particlesCnt = 5000;

   // Shift the duplicate geometries up and down
   const posArray = new Float32Array(particlesCnt * 3);

   for (let i = 0; i < particlesCnt * 3; i++) {
     posArray[i] = (Math.random() - 0.5) * (Math.random() * 10);
   }

   particlesGeometry.setAttribute(
     'position',
     new THREE.BufferAttribute(posArray, 3),
   );

   var particlesMaterial = new THREE.PointsMaterial({
     size: 0.007,
     map: cross,
     transparent: true,
   });

   var particlesMesh = new THREE.Points(
     particlesGeometry,
     particlesMaterial,
   );
   scene.add(particlesMesh);

   document.addEventListener("mousemove", animateParticles);

   let mouseX = 0;
   let mouseY = 0;
 
   function animateParticles(event) {
     mouseX = event.clientX;
     mouseY = event.clientY;
   }
 
   // Animation
   const clock = new THREE.Clock();
   let initialMouseX = 0;
   let initialMouseY = 0;
   let particleSpeed = 0.00001; // Adjust the speed as desired
   
   const tick = () => {
     const elapsedTime = clock.getElapsedTime();
     // console.log(elapsedTime);
   
     particlesMesh.rotation.y = -0.01 * elapsedTime;
   
     if (mouseY > 0 && mouseX > 0) {
       if (initialMouseX === 0 && initialMouseY === 0) {
         initialMouseX = mouseX;
         initialMouseY = mouseY;
       }
   
       const deltaMouseX = mouseX - initialMouseX;
       const deltaMouseY = mouseY - initialMouseY;
   
       camera.position.x = deltaMouseX * particleSpeed;
       camera.position.y = -deltaMouseY * particleSpeed;
     }

    //  stats.begin()
     renderer.render(scene, camera);
    //  stats.end()

     window.requestAnimationFrame(tick);
   };
   
   tick();

  /**************************************************
   * 3. Run the app
   * 'runApp' will do most of the boilerplate setup code for you:
   * e.g. HTML container, window resize listener, mouse move/touch listener for shader uniforms, THREE.Clock() for animation
   * Executing this line puts everything together and runs the app
   * ps. if you don't use custom shaders, pass undefined to the 'uniforms'(2nd-last) param
   * ps. if you don't use post-processing, pass undefined to the 'composer'(last) param
   *************************************************/
  runApp(app, scene, renderer, camera, true, composer);
}
