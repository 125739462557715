import ArrowRight from '../../assets/icons/arrow-right.svg';
import { Model } from '../../components';
import classes from './CallForm.module.scss';
import './contactForm.scss';
import emailjs from 'emailjs-com';

export function CallForm() {
  function onFormSubmit(e) {
    e.preventDefault();
    emailjs.init('TCit4K10G0UAzBfGP');
    emailjs
      .sendForm('service_xsryqae', 'template_qezm0uq', e.target)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }

  return (
    <div className={classes['call-form']}>
      <div className={classes['call-form__logo-container']}>
        <Model />
      </div>

      <div className="contact-form">
        <div className="content">
          <p className="description">
            It’s all about the humans and faces behind all things digital. For
            all things in between, please fill out the form below and we’ll
            connect with you
          </p>

          <a
            className="button"
            target="_blank"
            href="https://www.google.com/maps/place/Mach1+Digital/@28.5861164,77.3099646,17z/data=!3m1!4b1!4m6!3m5!1s0x390ce54f9cfa5b1b:0xecebaf772f7ba6b2!8m2!3d28.5861164!4d77.3125395!16s%2Fg%2F11rhqrqw4v?entry=ttu"
          >
            View on map
          </a>
        </div>
        <form action="" onSubmit={onFormSubmit}>
          <div className="input-group">
            <label htmlFor="name">Name*</label>
            <input type="text" name="name" id="name" required />
          </div>
          <div className="input-group">
            <label htmlFor="email">Email*</label>
            <input type="email" name="email" id="email" required />
          </div>
          <div className="input-group">
            <label htmlFor="message">Message*</label>
            <textarea name="message" id="message" rows="6"></textarea>
          </div>
          <button type="submit">
            <span className="text">Click Me</span>
            <img src={ArrowRight} alt="Arrow Right" />
          </button>
        </form>
      </div>
    </div>
  );
}
