import SpeakerLeft from '../../assets/images/speaker-left.svg';
import classes from './WhatWeDoComp.module.scss';

const DUMMY_DATA = [
  {
    id: 1,
    title: 'we analyse',
    description:
      'Data to gain insights and make informed decisions as per your brand niche. This involves conducting in-depth marketing research.',
  },
  {
    id: 2,
    title: 'we strategise',
    description:
      'Ideas that outline long-term brand development and prepare blueprints that align with tangible growth.',
  },
  {
    id: 3,
    title: 'we perform',
    description:
      'Targeted campaigns that encompass a holistic 360° marketing outlook, a comprehensive approach that covers various aspects of marketing to maximise our effectiveness and reach our desired goals.',
  },
  {
    id: 4,
    title: 'we deliver',
    description:
      'A solid Return on Investment (ROI), our focus is on ensuring that the resources and efforts invested in our marketing activities generate a favourable financial outcome.',
  },
];

export const WhatWeDoComp = () => {
  return (
    <section id='whatWeDo-section' className={classes.whatWeDo}>
      <div className={classes.whatWeDo__header}>
        <img
          className={`${classes.whatWeDo__speaker}`}
          src={SpeakerLeft}
          alt="Speaker"
        />
        <h3 className={classes.whatWeDo__heading}>What We Do?</h3>
        <img
          className={`${classes.whatWeDo__speaker} ${classes['whatWeDo__speaker--right']}`}
          src={SpeakerLeft}
          alt="Speaker"
        />
      </div>
      <div className={classes.whatWeDo__content}>
        {DUMMY_DATA.map((data, index) => (
          <div className={classes['whatWeDo__content--container']}>
            <div key={data.id} className={`${classes.whatWeDo__card} card-${index}`}>
              <h3 className={classes.whatWeDo__card__title}>{data.title}</h3>
              <p className={classes.whatWeDo__card__description}>
                {data.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
