import { useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import { Navbar, CanvasBackground, LenisScroll } from '../../components';
import SpaceAudio from '../../assets/audio/space.mp3';
import Lenis from '@studio-freight/lenis';

const spaceAudio = new Audio(SpaceAudio);
spaceAudio.loop = true;

const audioPermission = JSON.parse(sessionStorage.getItem('audio-permission'));
if (audioPermission) {
  spaceAudio.play().catch((e) => {
    window.addEventListener(
      'click',
      () => {
        spaceAudio.play();
      },
      { once: true },
    );
  });
}

const lenis = new Lenis({
  lerp: 0.1,
  wheelMultiplier: 0.5,
});

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

export function Layout() {
  const location = useLocation();
  const [audioPlay, setAudioPlay] = useState(audioPermission);
  const [showBanner, setBanner] = useState(
    audioPermission !== null ? false : true,
  );
  const [navbarOptionVisible, setNavbarOptionVisible] = useState(false);

  function onShowBannerClickHandler(e) {
    if (e.target.name === 'accept') {
      sessionStorage.setItem('audio-permission', true);
      onAudioButtonClickHandler();
    } else {
      sessionStorage.setItem('audio-permission', false);
    }
    setBanner(false);
  }

  function onAudioButtonClickHandler() {
    if (audioPlay) {
      // pause the audio
      spaceAudio.pause();
      setAudioPlay(false);
      sessionStorage.setItem('audio-permission', false);
    } else {
      // play the audio
      spaceAudio.play();
      setAudioPlay(true);
      sessionStorage.setItem('audio-permission', true);
    }
  }

  function turnOffAudio() {
    if (audioPlay) {
      spaceAudio.pause();
      setAudioPlay(false);
    }
  }

  function onNavbarOptionVisible(value) {
    setNavbarOptionVisible(value);
  }

  function onScrollStop() {
    // console.log('STOPPING NOW');
    lenis.stop();
  }

  function onScrollStart() {
    // console.log("STARTING NOW");
    lenis.start();
  }

  return (
    <>
      <Navbar
        navbarOptionVisible={onNavbarOptionVisible}
        audioPlay={audioPlay}
        audioToggle={onAudioButtonClickHandler}
        showBanner={showBanner}
        onShowBannerClick={onShowBannerClickHandler}
      />
      <CanvasBackground
        type={location.pathname === '/whatWeDo' ? 'spiral' : 'galaxy'}
      />
      <Outlet
        context={[
          turnOffAudio,
          navbarOptionVisible,
          { onScrollStart, onScrollStop },
        ]}
      />
    </>
  );
}
