import { useEffect, useRef } from 'react';
import classes from './Overlay.module.scss';

export function Overlay(props) {
  const overlayContainer = useRef();
  function onClickHandler(e) {
    const { clientX, clientY } = e;
    const overlayContainerDimension =
      overlayContainer.current.getBoundingClientRect();
    if (
      clientX < overlayContainerDimension.left ||
      clientX > overlayContainerDimension.right ||
      clientY < overlayContainerDimension.top ||
      clientY > overlayContainerDimension.bottom
    ) {
      props.onClose();
    }
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <section onClick={onClickHandler} className={classes.overlay}>
      <div
        ref={overlayContainer}
        className={`${classes.overlay__container} ${
          props.className ? props.className : ''
        }`}
      >
        {props.children}
      </div>
    </section>
  );
}
