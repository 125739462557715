import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import MonitorModel from '../../assets/models/led_tv.glb';

export function monitorCanvas(element) {
  //--------------------------------------------Scene--------------------------------------------
  const scene = new THREE.Scene();

  //--------------------------------------------Lights-------------------------------------------
  const ambientLight = new THREE.AmbientLight(0xff0000, 2.1);
  const directionalLight = new THREE.DirectionalLight(0xffffff, 2.8);
  directionalLight.position.z = 2;
  scene.add(ambientLight, directionalLight);

  const light = new THREE.PointLight(0xff0000, 1, 100);
  light.position.set(0, 0, 0);
  scene.add(light);

  //--------------------------------------------Debugging-----------------------------------------
  // const gui = new dat.GUI();

  //--------------------------------------------OBJLoader-----------------------------------------
  const objloader = new OBJLoader();

  //---------------------------------------1)Loading OBJ Model------------------------------------
  objloader.load(MonitorModel, (loadedObject) => {
    loadedObject.position.y = -1;
    loadedObject.scale.set(0.005, 0.005, 0.005);
    scene.add(loadedObject);

    // Assign the loaded object to the tvModel variable
    tvModel = loadedObject;
  });

  var planeGeometry = new THREE.PlaneGeometry(1, 1);

  var video = document.getElementById('video');

  video.play();

  var videoTexture = new THREE.VideoTexture(video);

  var material = new THREE.ShaderMaterial({
    side: THREE.DoubleSide,
    uniforms: {
      u_lightPos: { value: new THREE.Vector3(0, 0, 10) },
      u_texture: { value: videoTexture },
    },
    vertexShader: `
        varying vec3
        v_normal;
        varying vec3 v_position;
        varying vec2 v_uv;
        
        void main() {
            v_normal = normalMatrix * normal;
            v_position = vec3(modelMatrix * vec4(position, 1.0));
            v_uv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform sampler2D u_texture;
        varying vec2 v_uv;
        varying vec3 v_normal;
        varying vec3 v_position;
        uniform vec3 u_lightPos;

        void main() {
            vec3 normal = normalize(v_normal);
            vec3 lightDir = normalize(u_lightPos - v_position);
            float diffuse = max(dot(lightDir, normal), 0.0);
            gl_FragColor = texture2D(u_texture, v_uv) * diffuse;
        }
    `,
  });

  var plane = new THREE.Mesh(planeGeometry, material);
  plane.scale.set(1.45, 1.1, 0.5);
  plane.position.z = 1;
  plane.position.y = 0.135;
  scene.add(plane);
  //--------------------------------------------Resizing--------------------------------------------
  window.addEventListener('resize', () => {
    //Update Size
    aspect.width = window.innerWidth;
    aspect.height = window.innerHeight;

    //New Aspect Ratio
    camera.aspect = aspect.width / aspect.height;
    camera.updateProjectionMatrix();

    //New RendererSize
    renderer.setSize(aspect.width, aspect.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  });

  //---------------------------------------------Camera---------------------------------------------
  const aspect = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  const camera = new THREE.PerspectiveCamera(75, aspect.width / aspect.height);
  camera.position.z = 3;
  scene.add(camera);

  //--------------------------------------------Renderer--------------------------------------------
  const canvas = element;
  const renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
  renderer.physicallyCorrectLights = true;
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.setClearColor('#808080', 0);
  renderer.setSize(aspect.width, aspect.height);

  // //------------------------------------------OrbitControls------------------------------------------
  // const orbitControls = new OrbitControls(camera, canvas);
  // orbitControls.enableDamping = true;

  //------------------------------------------Clock Class--------------------------------------------
  const clock = new THREE.Clock();
  let previousTime = 0;
  let tvModel;
  let initialRotation = 0; // Initial rotation of the TV model
  let rotationSpeed = 0.002; // Speed of rotation
  let maxRotation = Math.PI / 10; // Maximum rotation in radians
  let planeRotation = 0; // Initial rotation for the plane

  const animate = () => {
    //---------------------------------------GetElapsedTime------------------------------------------
    const elapsedTime = clock.getElapsedTime();
    const frameTime = elapsedTime - previousTime;
    previousTime = elapsedTime;

    // Update the TV's rotation to tilt from left to right
    if (tvModel) {
      initialRotation += rotationSpeed;

      if (initialRotation > maxRotation) {
        rotationSpeed *= -1;
      } else if (initialRotation < -maxRotation) {
        rotationSpeed *= -1;
      }

      tvModel.rotation.y = initialRotation;
    }

    // Update the plane's rotation based on time and speed
    planeRotation += rotationSpeed;

    // Apply the rotation to the plane
    plane.rotation.y = planeRotation;

    // Update the object's position
    // object.position.x = zigzagX;
    // ------------------------------------Update animationMixer--------------------------------------
    // if (animationMixer) {
    //   animationMixer.update(frameTime);
    // }
    //---------------------------------------Update Controls-----------------------------------------
    // orbitControls.update();

    //-----------------------------------------Renderer----------------------------------------------
    renderer.render(scene, camera);

    //-----------------------------------RequestAnimationFrame---------------------------------------
    window.requestAnimationFrame(animate);
  };
  animate();
}
