import { useEffect, useRef, useState } from 'react';
import classes from './FaceCanvas.module.scss';
import { faceCanvasUtil, handleUI } from './face.script';

export function FaceCanvas(props) {
  const canvasRef = useRef();
  const buttonRef = useRef();
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    faceCanvasUtil(canvasRef.current);
  }, []);

  function onClickHandler() {
    handleUI(clickCount + 1);
    if (clickCount === 0) {
      buttonRef.current.classList.add(classes['contact__call-btn--glow']);
    }
    if(clickCount === 1) {
      console.log("COMING HERE")
      setTimeout(()=>{
        const louderText = document.querySelectorAll(`.${classes['contact__loud-text']}`);
      console.log(louderText)
      louderText.forEach((tag, index) => {
        setTimeout(()=>{
          tag.classList.add(classes['contact__loud-text--vanish'])
        }, (louderText.length - 1 - index)*200 + 1800);
      })
      }, 1200);
    }
    if (clickCount === 2) {
      props.onFinalClick();
    }
    setClickCount((prev) => prev + 1);
  }

  return (
    <section className={classes.contact}>
      <canvas className={classes.contact__canvas} ref={canvasRef}></canvas>
      <div className={classes['contact__container']}>
        {clickCount === 2 && (
          <>
            <h3 className={classes['contact__loud-text']}>louder!!</h3>
            <h3 className={classes['contact__loud-text']}>louder!!</h3>
            <h3 className={classes['contact__loud-text']}>louder!!</h3>
            <h3 className={classes['contact__loud-text']}>louder!!</h3>
            <h3 className={classes['contact__loud-text']}>louder!!</h3>
          </>
        )}

        <button
          ref={buttonRef}
          onClick={onClickHandler}
          className={classes['contact__call-btn']}
        >
          call me{' '}
          {clickCount === 1 ? 'again' : clickCount === 2 ? 'again!!' : ''}
        </button>
      </div>
    </section>
  );
}
