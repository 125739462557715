import * as THREE from 'three'

export function circleThreejsUtil(canvasRef) {
    // Canvas
    const canvasDimension = canvasRef.getBoundingClientRect();
const canvas = canvasRef;

// Scene
const scene = new THREE.Scene()

// Objects
const geometry = new THREE.TorusGeometry( 1.2, .2, 60, 100 );

// Materials

const material = new THREE.PointsMaterial({
    size: 0.01,
})
material.color = new THREE.Color(0xff0000)

// Mesh
const sphere = new THREE.Points(geometry,material)
scene.add(sphere)

// Lights

const pointLight = new THREE.PointLight(0xffffff, 0.1)
pointLight.position.x = 2
pointLight.position.y = 3
pointLight.position.z = 4
scene.add(pointLight)

/**
 * Sizes
 */
const sizes = {
    width: canvasDimension.width,
    height: canvasDimension.height
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = canvasDimension.width
    sizes.height = canvasDimension.height

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(window.devicePixelRatio)
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 4
scene.add(camera)


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    antialias: false,
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(window.devicePixelRatio * 0.8)

/**
 * Animate
 */

const clock = new THREE.Clock()

// Function to animate the particles like a music amplifier
function animateParticlesLikeAmplifier(geometry, amplitude, frequency, elapsedTime) {
    const positions = geometry.attributes.position.array;
    const count = positions.length / 3;
    for (let i = 0; i < count; i++) {
      const yIndex = i * 10; // The y-coordinate index for the particle
      const x = positions[yIndex];
      
      // Calculate the new y-coordinate based on a sine function with amplitude and frequency
      positions[yIndex] = Math.sin(frequency * (x + elapsedTime)) * amplitude;
    }
    geometry.attributes.position.needsUpdate = true;
  }
  
  // Inside the tick function, update the particle positions to animate like a music amplifier
  const tick = () => {
    const elapsedTime = clock.getElapsedTime();
  
    // Update objects
    sphere.rotation.z = -0.2 * elapsedTime;
  
    // Animate the particles like a music amplifier
    const amplitude = 1.9; // Adjust the amplitude of the wave
    const frequency = .6;   // Adjust the frequency of the wave
    animateParticlesLikeAmplifier(geometry, amplitude, frequency, elapsedTime);
  
    // Render
    renderer.render(scene, camera);
    renderer.setClearColor(new THREE.Color("#000"), 0);
  
    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
  }
  
  tick();
}