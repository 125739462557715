import { useEffect, useRef } from 'react';
import classes from './Imagine.module.scss';
import { circleThreejsUtil } from './Imagine.script';
import RedArrow from '../../assets/icons/red-arrow.svg'

export function Imagine() {
  const headingRef = useRef();
  const canvasRef = useRef();

  useEffect(() => {
    circleThreejsUtil(canvasRef.current);
    let currentActiveNode = 0;
    const spanTagList = headingRef.current.querySelectorAll('span');
    const setIntervalID = setInterval(() => {
      const index = currentActiveNode % spanTagList.length;
      spanTagList[index].classList.toggle('display-none');
      spanTagList[index].classList.toggle(classes['fade-in']);
      if (currentActiveNode > 0) {
        if (index - 1 < 0) {
          spanTagList[spanTagList.length - 1].classList.toggle('display-none');
          spanTagList[spanTagList.length - 1].classList.toggle(
            classes['fade-in'],
          );
        } else {
          spanTagList[index - 1].classList.toggle('display-none');
          spanTagList[index - 1].classList.toggle(classes['fade-in']);
        }
      }
      currentActiveNode += 1;
    }, 1800);

    return () => {
      clearInterval(setIntervalID);
    };
  }, []);

  return (
    <section className={classes.imagine}>
      <div className={classes.imagine__content}>
        <p className={classes.imagine__content__heading}>You Imagine.</p>
        <p ref={headingRef} className={classes.imagine__content__heading}>
          We do the rest <span className="display-none">Facebook.</span>
          <span className="display-none">Instagram.</span>
          <span className="display-none">Twitter.</span>
          <span className="display-none">Website.</span>
        </p>
        <p className={classes.imagine__content__description}>
          We offer tailor-made solutions to our clients suiting their aims and
          objectives with custom timelines and work processes
        </p>
      </div>
      <div className={classes.imagine__image}>
        <canvas ref={canvasRef}></canvas>
      <img src={RedArrow} alt="Red Arrow" />
      </div>
    </section>
  );
}
