import classes from './Monitor.module.scss';
import MonitorImg from '../../assets/images/monitor.png';
import { Carousel } from 'react-responsive-carousel';
import Galaxy1 from '../../assets/images/galaxy1.jpg';
import Galaxy2 from '../../assets/images/galaxy2.jpg';
import Galaxy3 from '../../assets/images/galaxy3.jpg';
import { useEffect, useRef } from 'react';
import Mach1VideoFlat from '../../assets/video/mach1_flat.mp4';
import { monitorCanvas } from './monitor.script';

export function Monitor() {
  const canvasRef = useRef(null);

  useEffect(() => {
    monitorCanvas(canvasRef.current);
  }, []);

  return (
    <section id="monitor-section" className={classes['monitor-section']}>
      <div
        className={`${classes['monitor-section__strip']} ${classes['monitor-section__strip--left']}`}
      >
        <p
          className={classes['monitor-section__strip__marquee']}
          data="10X YOUR PRESENCE  •  10X YOUR PRESENCE"
        ></p>
      </div>
      <div
        className={`${classes['monitor-section__strip']} ${classes['monitor-section__strip--right']}`}
      >
        <p
          className={classes['monitor-section__strip__marquee']}
          data="10X YOUR SALES  •  10X YOUR SALES"
        ></p>
      </div>
      {/* <div
        className={`monitor-section__monitor ${classes['monitor-section__monitor']}`}
      >
        <img
          className={classes['monitor-section__monitor--image']}
          src={MonitorImg}
          alt="Monitor"
        />
        <div className={classes['monitor-section__monitor--screen']}>
          <Carousel autoPlay infiniteLoop>
            <img src={Galaxy1} alt="" />
            <img src={Galaxy2} alt="" />
            <img src={Galaxy3} alt="" />
          </Carousel>
        </div>
      </div> */}
      <video
        style={{ display: 'none' }}
        src={Mach1VideoFlat}
        id="video"
        muted
      ></video>
      <canvas
        ref={canvasRef}
        style={{ position: 'absolute' }}
      />
    </section>
  );
}
