import './ImageMarquee.scss';
import PalaceImg from '../../assets/images/palace.jpg';
import Palace1Img from '../../assets/images/palace_1.jpg';
import { Carousel } from 'react-responsive-carousel';
import { useEffect, useRef } from 'react';

export function ImageMarquee() {
  const marquee1 = useRef(null);
  const marquee2 = useRef(null);
  useEffect(() => {
    marquee1.current.addEventListener('mouseenter', (e) => {
      marquee1.current.classList.add('onHover');
    });
    marquee1.current.addEventListener('mouseleave', (e) => {
      marquee1.current.classList.remove('onHover');
      marquee1.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
    marquee2.current.addEventListener('mouseenter', (e) => {
      marquee2.current.classList.add('onHover');
    });
    marquee2.current.addEventListener('mouseleave', (e) => {
      marquee2.current.classList.remove('onHover');
      marquee2.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  }, []);
  return (
    <article id='image-marquee-container' className="wrapper">
      <div className="marquee group-1" ref={marquee1}>
        <div className="marquee__group">
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
        </div>

        <div aria-hidden="true" className="marquee__group">
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
          <img src={Palace1Img} alt="" />
        </div>
      </div>

      <div className="marquee group-2 marquee--reverse" ref={marquee2}>
        <div className="marquee__group">
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
        </div>

        <div aria-hidden="true" className="marquee__group">
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
          <img src={PalaceImg} alt="" />
        </div>
      </div>
    </article>
    // <Carousel showArrows={false} showIndicators={false} showThumbs={false} className='image-carousel' autoPlay infiniteLoop>
    //   <img src={PalaceImg} alt="" />
    //   <img src={PalaceImg} alt="" />
    //   <img src={PalaceImg} alt="" />
    //   <img src={PalaceImg} alt="" />
    //   <img src={PalaceImg} alt="" />
    //   <img src={PalaceImg} alt="" />
    //   <img src={PalaceImg} alt="" />
    // </Carousel>
  );
}
