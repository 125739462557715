import classes from './Card.module.scss';

export function Card({ data, index, onCardClick, className, isOverlay }) {

  function onCardClickHandler () {
    if(onCardClick) {
      onCardClick({data, index});
    }
  }

  return (
    <div onClick={onCardClickHandler} key={data.id} className={`${classes['card']} ${className} ${isOverlay ? classes['card__overlay'] : ''}`}>
      <div className={classes['card__header']}>
        <h3>{data.heading}</h3>
        <span>{String(index).padStart(2, '0')}/</span>
      </div>
      <div className={classes['card__content']}>
        <p className={classes['card__description']}>{data.subHeading}</p>
        <p className={classes['card__description--list']}>
          {data.list.description}
        </p>
        <ul className={classes['card__list']}>
          {data.list.items.map((item, index) => (
            <li key={`list-item${index+1}`} className={classes['card__list--item']}>
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
