import classes from './Footer.module.scss';
import { useNavigate } from 'react-router-dom';
import { Model } from '../Model/Model';

const date = new Date();

export function Footer() {
  const navigate = useNavigate();

  function onLinkClickHandler(e) {
    const { id } = e.target;
    navigate(`/${id}`);
  }

  return (
    <div className={classes.frost}>
      <footer className={classes.footer}>
      <div className={classes.footer__about}>
        <div className={classes.footer__heading}>
          <h3>You mean business</h3>
          <h3>we Mean business</h3>
        </div>
        <ul className={classes.footer__list}>
          <li>Social Media Marketing</li>
          <li>E-Commerce Management</li>
          <li>Paid Ads Management</li>
          <li>UI/UX Website & APP Design</li>
        </ul>
        <p className={classes.footer__para}>
          ©{date.getFullYear()} Mach1 Digital LLP. All Rights Reserved
        </p>
      </div>
      <Model />
      <div className={classes.footer__navigation}>
        <div className={classes.footer__heading}>
          <h3>+91-9818511171</h3>
          <h3>{`hello@mach1.in`}</h3>
        </div>
        <ul className={classes.footer__list}>
          <li id="home" onClick={onLinkClickHandler}>
            home
          </li>
          <li id="aboutUs" onClick={onLinkClickHandler}>
            about us
          </li>
          <li id="whatWeDo" onClick={onLinkClickHandler}>
            what we do ?
          </li>
          <li id="callMe" onClick={onLinkClickHandler}>
            call me
          </li>
        </ul>
        <p className={classes.footer__para}>
          2nd Floor, A-35, A Block, Sector 2, Noida, Uttar Pradesh 201301
        </p>
      </div>
    </footer>
    </div>
  );
}
