import { useLayoutEffect, useRef } from 'react';
import {
  AITool,
  Editor,
  Footer,
  Hero,
  ImageMarquee,
  Imagine,
  Marketing,
  Monitor,
  Video,
} from '../../components';
import { gsap } from 'gsap';
import { useOutletContext } from 'react-router';

export function Home() {
  const pageRef = useRef();

  const [turnOffAudio, navbarOptionVisible, scrollObject] = useOutletContext();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const videoTL = gsap.timeline({
        scrollTrigger: {
          trigger: '#video-section',
          pin: '#video-section',
          pinReparent: false,
          anticipatePin: true,
          start: '0% top',
          end: '50%',
          scrub: 3,
        },
      });

      videoTL.from('.video-container', {
        // x: 500,
        // y: 500,
        scale: 0.2,
        duration: 1,
        opacity: 0.25,
      });
      videoTL.to('.video-container', {
        // x: -900,
        // y: -900,
        duration: 3,
        scale: 2.5,
        opacity: 0.05,
        // height:0,
        // position: 'fixed',
        // top: '0'
      });

      videoTL.to('.video-container span', {
        opacity: 0
      })

      const marketingTL = gsap.timeline({
        scrollTrigger: {
          trigger: '#marketing-section',
          pin: '#marketing-section',
          pinReparent: false,
          anticipatePin: true,
          start: '0% top',
          end: '100%',
          scrub: 3,
          immediateRender: false,
        },
      });
      marketingTL.from('.marketing-headers', {
        x: 800,
        duration: 1,
        opacity: 0,
      });
      marketingTL.from('.marketing-card-list', {
        x: 500,
        duration: 1,
        opacity: 0,
      });
      marketingTL.to('.marketing-card-list', {
        duration: 10,
      });

      const monitorTL = gsap.timeline({
        scrollTrigger: {
          trigger: '#monitor-section',
          pin: '#monitor-section',
          pinReparent: false,
          anticipatePin: true,
          start: '0% top',
          end: '50%',
          scrub: 3,
          immediateRender: false,
        },
      });

      monitorTL.from('#monitor-section canvas', {
        x: '25%',
        y: '-30%',
        duration: 1,
        scale: 0.25,
        rotate: -60,
      });

      const imageMarqueeTL = gsap.timeline({
        scrollTrigger: {
          trigger: '#image-marquee-container',
          start: '-50% top',
          end: '-50%',
          scrub: 10,
        },
      });

      imageMarqueeTL.from('.marquee.group-1', {
        x: '100%',
        duration: 1,
      });

      imageMarqueeTL.from('.marquee.group-2', {
        x: '-100%',
        duration: 1,
      });

      // const monitorTL = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: '#monitor-section',
      //     pin: '#monitor-section',
      //     pinReparent: false,
      //     anticipatePin: true,
      //     start: '0% top',
      //     end: '100%',
      //     scrub: 3,
      //   },
      // });

      // monitorTL.from('.monitor-section__monitor', {
      //   // y: -350,
      //   scale: 0.4,
      //   duration: 1,
      //   rotate: -70,
      // });
      // monitorTL.to('.monitor-section__monitor', {
      //   // y: -350,
      //   scale: 1,
      //   duration: 1,
      //   // rotate: 170,
      // });
    }, pageRef);

    return () => {
      ctx.revert();
    };
  }, []);

  return (
    <div ref={pageRef}>
      <Hero
        content={{ heading: 'Attention!', description: 'Let’s Get Started' }}
        showContent={!navbarOptionVisible}
      />
      <Video turnOffAudio={turnOffAudio} />
      <Marketing scrollAction={{ ...scrollObject }} />
      <Monitor />
      <Imagine />
      <Editor />
      <AITool />
      <ImageMarquee />
      <Footer />
    </div>
  );
}
