import Mach1Video from '../../assets/video/mach1.mp4';
import Mach1VideoFlat from '../../assets/video/mach1_flat.mp4';
import classes from './Video.module.scss';
import scrollTrigger from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';

gsap.registerPlugin(scrollTrigger);

export function Video(props) {
  function onVideoClickHandler(e) {
    e.target.requestFullscreen();
    e.target.addEventListener('fullscreenchange', (e) => {
      if (document.fullscreenElement) {
        e.target.classList.remove(classes['video__video-player__mask']);
        e.target.firstChild.src = Mach1VideoFlat;
        e.target.load();
        e.target.muted = false;
        props.turnOffAudio();
      } else {
        e.target.firstChild.src = Mach1Video;
        e.target.muted = true;
        e.target.classList.add(classes['video__video-player__mask']);
      }
    });
  }

  return (
    <section id="video-section" className={classes.video}>
      <div className={`${classes['video__video-container']} video-container`}>
        <video
          onClick={onVideoClickHandler}
          className={`${classes['video__video-player']} ${classes['video__video-player__mask']}`}
          loop
          autoPlay
          muted
        >
          <source type="video/mp4" src={Mach1Video} />
        </video>
        <span>Click Video for Full Screen</span>
      </div>
    </section>
  );
}
