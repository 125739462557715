import { useEffect, useRef, useState } from 'react';
import { MARKETING_DATA } from './marketing.data';
import { Card } from '../Card/Card';
import '../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import classes from './Marketing.module.scss';
import { Overlay } from '../Overlay/Overlay';

export function Marketing(props) {
  const cardListRef = useRef();
  const marketingSectionRef = useRef();
  const [overlay, setOverlay] = useState(null);

  function onCardClickHandler(data) {
    marketingSectionRef.current.scrollIntoView();
    setOverlay(data);
    props.scrollAction.onScrollStop();
  }

  function onOverlayCloseHandler() {
    setOverlay(null);
    props.scrollAction.onScrollStart();
  }

  // useEffect(() => {
  //   if (window.innerWidth >= 992) {
  //     let mouseEventID = undefined;
  //     document.addEventListener('mousemove', (e) => {
  //       if (mouseEventID) {
  //         clearTimeout(mouseEventID);
  //       }
  //       mouseEventID = setTimeout(() => {
  //         // console.log(e);
  //         const cards = cardListRef.current.querySelectorAll(
  //           `.${classes['marketing__card']}`,
  //         );
  //         cards.forEach((card) => {
  //           const dimension = card.getBoundingClientRect();
  //           if(e.x >= dimension.left + 10 && e.x <= dimension.right - 10 && e.y >= dimension.top + 10 && e.y <= dimension.bottom - 10) {
  //             card.style.flex = 1;
  //           } else {
  //             setTimeout(()=>{
  //               card.style.flex = '';
  //             }, 300)
  //           }
  //         });
  //       }, 100);
  //     });
  //   }
  // }, []);

  return (
    <section ref={marketingSectionRef} id="marketing-section" className={classes.marketing}>
      <div className={`${classes['marketing__container']}`}>
        <h2 className={`${classes['marketing__header']} marketing-headers`}>
          marketing made supersonic
        </h2>
        <p className={`${classes['marketing__sub-heading']} marketing-headers`}>
          Connecting you to your customers at the speed of sound
        </p>
        {window.innerWidth >= 992 && (
          <div
            ref={cardListRef}
            className={`${classes['marketing__card-list']} marketing-card-list`}
          >
            {MARKETING_DATA.map((data, index) => (
              <Card
                className={`${classes['marketing__card']} marketing-card${index} ${classes['marketing__card--hover']}`}
                key={data.id}
                data={data}
                index={index + 1}
                onCardClick={onCardClickHandler}
              />
            ))}
          </div>
        )}

        {window.innerWidth < 992 && (
          <Carousel autoPlay infiniteLoop>
            {MARKETING_DATA.map((data, index) => (
              <div
                style={{
                  padding: '1.5rem',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                key={data.id}
              >
                <Card
                  className={classes['marketing__card']}
                  key={data.id}
                  data={data}
                  index={index + 1}
                  onCardClick={onCardClickHandler}
                />
              </div>
            ))}
          </Carousel>
        )}
      </div>
      {overlay && (
        <Overlay onClose={onOverlayCloseHandler}>
          <Card
            data={overlay.data}
            index={overlay.index}
            className={classes['marketing__card--overlay']}
            isOverlay={true}
          />
        </Overlay>
      )}
    </section>
  );
}
