import * as THREE from 'three';
import stars from '../../assets/images/circle.png';

export function galaxyAnimation(canvasRef) {
  const loader = new THREE.TextureLoader();
  const cross = loader.load(stars);

  // Custom Shaders
  const vertexShader = `
  varying vec2 vUv;

  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

  const fragmentShader = `
  uniform vec3 colorTop1;
  uniform vec3 colorBottom1;
  uniform vec3 colorTop2;
  uniform vec3 colorBottom2;
  uniform float uTime;

  varying vec2 vUv;

  void main() {
    vec3 color1 = mix(colorBottom1, colorTop1, vUv.y);
    vec3 color2 = mix(colorBottom2, colorTop2, vUv.y);
    float t = sin(vUv.x * 2. + uTime) * .8 + .5;
    vec3 finalColor = mix(color1, color2, t);

    gl_FragColor = vec4(finalColor, 1.0);
  }
`;

  // Canvas
  const canvas = canvasRef;
  // Scene
  const scene = new THREE.Scene();

  // Gradient Background
  const gradientUniforms = {
    colorTop1: { value: new THREE.Color('#733c3c') },
    colorBottom1: { value: new THREE.Color('#000000') },
    colorTop2: { value: new THREE.Color('#000000') },
    colorBottom2: { value: new THREE.Color('#684a91') },
    uTime: { value: 0 },
  };

  const gradientMaterial = new THREE.ShaderMaterial({
    uniforms: gradientUniforms,
    vertexShader,
    fragmentShader,
    depthTest: false,
  });

  const gradientGeometry = new THREE.PlaneGeometry(20, 5);
  const gradientMesh = new THREE.Mesh(gradientGeometry, gradientMaterial);
  scene.add(gradientMesh);

  // Buffer Geometry
  const particlesGeometry = new THREE.BufferGeometry();
  const particlesCnt = 5000;

  // Shift the duplicate geometries up and down
  const posArray = new Float32Array(particlesCnt * 3);

  for (let i = 0; i < particlesCnt * 3; i++) {
    posArray[i] = (Math.random() - 0.5) * (Math.random() * 10);
  }

  particlesGeometry.setAttribute(
    'position',
    new THREE.BufferAttribute(posArray, 3),
  );

  const particlesMaterial = new THREE.PointsMaterial({
    size: 0.005,
    map: cross,
    transparent: true,
  });

  const particlesMesh = new THREE.Points(particlesGeometry, particlesMaterial);
  scene.add(particlesMesh);

  /**
   * Sizes
   */
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(window.devicePixelRatio * 0.8);
  });

  /**
   * Camera
   */
  const camera = new THREE.PerspectiveCamera(
    75,
    sizes.width / sizes.height,
    0.1,
    100,
  );
  camera.position.set(0, 0, 2);
  scene.add(camera);

  /**
   * Renderer
   */
  const renderer = new THREE.WebGLRenderer({
    antialias: true,
    canvas: canvas,
  });
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  document.addEventListener("mousemove", animateParticles);

  let mouseX = 0;
  let mouseY = 0;

  function animateParticles(event) {
    mouseX = event.clientX;
    mouseY = event.clientY;
  }

  // Animation
  const clock = new THREE.Clock();
  let initialMouseX = 0;
  let initialMouseY = 0;
  let particleSpeed = 0.00001; // Adjust the speed as desired
  
  const tick = () => {
    const elapsedTime = clock.getElapsedTime();
    // console.log(elapsedTime);
  
    particlesMesh.rotation.y = -0.01 * elapsedTime;
  
    if (mouseY > 0 && mouseX > 0) {
      if (initialMouseX === 0 && initialMouseY === 0) {
        initialMouseX = mouseX;
        initialMouseY = mouseY;
      }
  
      const deltaMouseX = mouseX - initialMouseX;
      const deltaMouseY = mouseY - initialMouseY;
  
      camera.position.x = deltaMouseX * particleSpeed;
      camera.position.y = -deltaMouseY * particleSpeed;
    }
  
    // Animate the gradient colors
    gradientMaterial.uniforms.uTime.value = elapsedTime;
  
    renderer.render(scene, camera);
  
    window.requestAnimationFrame(tick);
  };
  
  tick();
  
}
