import { useEffect, useState } from 'react';
import { CallForm, FaceCanvas } from '../../components';
// import { useOutletContext } from 'react-router';

export function CallMe() {
  const [isFaceShown, setFace] = useState(null);
  // const contextFunctions = useOutletContext();

  useEffect(()=>{
    setFace(JSON.parse(localStorage.getItem('face-animation')));
  },[])

  function onFinalClickHandler() {
    setFace(false);
    localStorage.setItem('face-animation', false);
  }

  return (
    <>
          {isFaceShown && <FaceCanvas onFinalClick={onFinalClickHandler} />}
          {!isFaceShown && <CallForm />}
    </>
  );
}
