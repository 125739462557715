import classes from './AnimateText.module.scss';

/**
 *
 * @param {text, keyValue, link, className} props
 */
export function AnimateText(props) {
  function onClickHandler() {
    props.onClick(props.link);
  }
  return (
    <div
      onClick={onClickHandler}
      className={`${classes['text-animate']} ${
        props.className ? props.className : ''
      }`}
    >
      <span>{props.text}</span>
    </div>
  );
}
